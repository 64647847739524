import React from 'react'
import get from 'lodash/get'

import { ScrollToTopOfPage, ContentfulSitePage } from 'blog-components'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'

// eslint-disable-next-line
import SiteInformation from '../fragments/SiteInformationFragment'
// eslint-disable-next-line
import ShareImages from '../fragments/ShareImagesFragment'

class PageTemplate extends ScrollToTopOfPage {
  render() {
    const site = get(this, 'props.data.site')

    const title = get(this, 'props.data.page.title')
    const description = get(
      this,
      'props.data.page.description.childMarkdownRemark.rawMarkdownBody'
    )
    const slug = get(this, 'props.data.page.slug')
    const shareImage = get(this, 'props.data.page.shareImage.fixed_1200x630.src')
    const htmlAst = get(
      this,
      'props.data.page.body.childMarkdownRemark.htmlAst'
    )

    return (
      <Layout>
        <div className="container fadein-3 page" data-emergence="visible">
          <ContentfulSitePage
            site={site}
            title={title}
            description={description}
            slug={slug}
            shareImage={shareImage}
            htmlAst={htmlAst}
          />
        </div>
      </Layout>
    )
  }
}

export default PageTemplate

export const pageQuery = graphql`
  query ContentfulPageByPath($path: String!) {
    site {
      ...SiteInformation
    }
    page: contentfulPage(slug: { eq: $path }) {
      title
      slug
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      body {
        childMarkdownRemark {
          htmlAst
        }
      }
      shareImage {
        ...ShareImages
      }
    }
  }
`
